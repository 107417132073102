import OutputView from '@components/block/OutputView/OutputView';
import { CloseButton } from '@components/ui/CloseButton/CloseButton';
import { Modal, Paper } from '@mui/material';

import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { EmailModal } from '@components/ui/EmailModal/EmailModal';
import { UserQuestionnaireApplication } from '@dieterApi/user/useUserQuery';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import cx from 'classnames';
import { animated, useSpring } from 'react-spring';
import './documentitem.sass';

export function DocumentPaper() {
  const { user } = useUser();
  const {
    navigation: { isMobile, documentPaper, dashboardLocked, roadBlock },
    setNavigation,
  } = useNavigation();
  const { questionnaire, index, modalOpen } = documentPaper;
  const animatePaper = useSpring({
    transform:
      (!user?.email || !user.confirmed || roadBlock.modalOpen) && !isMobile ? 'translateX(30%)' : 'translateX(0%)',
  });

  const appIsPublic = questionnaire?.application.tags?.map((t) => t.tag).includes('public');

  const onClose = () => {
    user?.email && user?.confirmed && setNavigation((nav) => void (nav.documentPaper.modalOpen = false));
    if (dashboardLocked) {
      // this triggers, when a user wants to close the document but dashboard is still
      // locked. Then we require the entry of the password first.
      setNavigation((nav) => void (nav.passwordModalOpen = true));
    }
  };

  // after a questionnaire has been opened, it is possibly recreated and could be marked as UNTOUCHED or INCOMPLETE
  // due to a version update. In this case we need to close the document and inform the user about the update.
  const handleOpenQuestionnaire = (localQuestionnaire: UserQuestionnaireApplication) => {
    if (localQuestionnaire.state !== QuestionnaireState.COMPLETE && !dashboardLocked) {
      onClose();
      setNavigation((nav) => {
        nav.questionnaireHasChanged = {
          modalOpen: true,
          questionnaire: localQuestionnaire,
        };
      });
    }
  };

  return (
    <Modal open={documentPaper.modalOpen} onClose={onClose} sx={{ zIndex: 30 }}>
      <div className="pointer-events-none">
        <animated.div style={animatePaper} className="h-screen relative pointer-events-none">
          <Paper
            className={cx(
              !questionnaire && 'hidden',
              'absolute top-1/2 left-1/2 overflow-y-auto overflow-x-hidden h-full md:h-[90vh] w-full md:w-[80vw] max-w-[800px] -translate-y-1/2 -translate-x-1/2 z-30 pointer-events-auto'
            )}
            elevation={10}
          >
            {user?.email && <CloseButton onClick={onClose} offset={false} btnColor="white" />}
            {questionnaire && (
              <OutputView
                localQuestionnaireId={questionnaire.id}
                documentNumber={questionnaire.application.splitDocument ? index + 1 : undefined}
                onClose={onClose}
                locked={
                  dashboardLocked || roadBlock.modalOpen || (!appIsPublic && (!user?.isSubscriber || user?.isTrialing))
                }
                trialing={user?.isTrialing}
                onOpenQuestionnaire={handleOpenQuestionnaire}
              />
            )}
          </Paper>
        </animated.div>
        <EmailModal />
      </div>
    </Modal>
  );
}
