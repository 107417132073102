import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { useDashboard } from '@app/routes/Dashboard/useDashboard';
import { copyToClipboard } from '@app/utils/copyToClipboard';
import { Button, NotificationModal, Spinner } from '@components/ui';
import { Icon } from '@components/ui/Icon/Icon';
import { useOpenQuestionnaireMutation } from '@dieterApi/questionnaire/useOpenQuestionnaireMutation';
import { useGetQuestionnaireApiDocQuery } from '@dieterApi/questionnaire/useQuestionnaireApiDocQuery';
import {
  ConsentFrom,
  useQuestionnaireDocumentShareQuery,
} from '@dieterApi/questionnaire/useQuestionnaireDocumentShareQuery';
import { useGetQuestionnaireHtmlQuery } from '@dieterApi/questionnaire/useQuestionnaireHtmlQuery';
import CodeIcon from '@mui/icons-material/Code';
import DataArrayIcon from '@mui/icons-material/DataArray';
import ExtensionIcon from '@mui/icons-material/Extension';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShareIcon from '@mui/icons-material/Share';
import { Menu, MenuItem, ModalProps, Skeleton, Tooltip } from '@mui/material';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LangSelect from '../Header/LangSelect';
interface Props {
  questionnaireId: string;
  documentNumber?: number;
}

export function ConsumerPanel({ questionnaireId, documentNumber = 1 }: Props) {
  // hooks
  const { t } = useTranslation();
  const { navigation } = useNavigation();
  const dashboard = useDashboard();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [openQuestionnaire, { data: questionnaireData, loading: questionnaireLoading }] =
    useOpenQuestionnaireMutation();
  const [getHtml, { data: htmlData, loading: htmlLoading }] = useGetQuestionnaireHtmlQuery();
  const [getShareToken, { data: shareToken, loading: shareTokenLoading }] = useQuestionnaireDocumentShareQuery();
  const [getApiDoc, { data: apiDocData }] = useGetQuestionnaireApiDocQuery();
  const apiDocId = apiDocData?.getQuestionnaireApiDoc;

  // states
  const [hasCopied, setHasCopied] = useState(false);
  const [chunkModalOpen, setChunkModalOpen] = useState(false);
  const [pluginModalOpen, setPluginModalOpen] = useState(false);
  const [downloadType, setDownloadType] = useState<'pdf' | 'docx' | 'html' | null>('pdf');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const questionnaire = questionnaireData?.openQuestionnaire;
  const consentFrom = (user?.userValues.find(
    (uv) => uv.key === 'CONSENT_FROM' && uv.index === questionnaire?.userValueIndex
  )?.value || '') as ConsentFrom;

  const tags = questionnaire?.application.tags?.map((tag) => tag.tag);
  const html = tags?.includes('html');
  const pdf = tags?.includes('pdf');
  const docx = tags?.includes('docx');
  const consentlink = tags?.includes('consentlink');
  const loading = questionnaireLoading || htmlLoading || shareTokenLoading;

  useEffect(() => {
    openQuestionnaire({
      variables: {
        localQuestionnaireId: questionnaireId,
      },
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    if (html) {
      getHtml({
        variables: { id: questionnaireId },
      });
      if (!questionnaireId) return;
      getApiDoc({
        variables: {
          id: questionnaireId,
          locale: user?.locale,
        },
      });
    }
  }, [html, questionnaireId, user?.locale]);

  useEffect(() => {
    if (consentlink) {
      console.log('TRIGGERED', consentlink);
      getShareToken({
        variables: {
          localQuestionnaireId: questionnaireId,
          type: 'ConsentForm',
          consentFrom,
          withConfirmation: true,
        },
      });
    }
  }, [consentlink]);

  if (!questionnaire) return <div className={`h-12 w-full bg-gray-200 animate-pulse`} />;

  const fileName = user?.company.name + ' ' + questionnaire.application.documentLabels[documentNumber - 1];

  const handleDownload = (type: 'docx' | 'pdf') => {
    setDownloadType(type);
    dashboard.download(questionnaire.id, fileName, type);
  };

  const isDownloading = dashboard.affects('download', questionnaire.id);

  const handlePrint = () => {
    const path = `/print/${questionnaire.id}${
      questionnaire.application.splitDocument ? '?documentNumber=' + documentNumber : ''
    }`;
    window.open(path, '_blank', 'noopener,noreferrer');
  };

  const handleCopyHtml = () => {
    htmlData &&
      copyToClipboard(htmlData.getQuestionnaireHtml[0])
        .then(() => {
          setHasCopied(true);
          if (navigation.isMobile) {
            enqueueSnackbar(t('dashboard.topic.process.publishprocess.copy_success'), { variant: 'success' });
          }
        })
        .catch((error) => {
          enqueueSnackbar(t('components.block.OutputView.ConsumerPanel.copyerror'), { variant: 'error' });
        });
  };

  const handleCopyConsentLink = () => {
    const url = shareToken ? window.location.origin + '/share?token=' + shareToken.getQuestionnaireShareToken : '';
    url &&
      copyToClipboard(url)
        .then(() => {
          setHasCopied(true);
          if (navigation.isMobile) {
            enqueueSnackbar(t('dashboard.topic.process.publishprocess.copy_success'), { variant: 'success' });
          }
        })
        .catch((error) => {
          enqueueSnackbar(t('components.block.OutputView.ConsumerPanel.copyerror'), { variant: 'error' });
        });
  };

  const handleMobileClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div className="bg-gray-300 w-full py-2 flex px-2 gap-4 justify-between  relative">
      {html && (
        <div className=" absolute text-xs flex gap-2 items-center bg-secondary-200 px-2 rounded-md right-0 -bottom-5">
          <span className="text-gray-400">ID:</span>{' '}
          {apiDocId ? (
            <span className="cursor-text z-50 text-gray-600">{apiDocId}</span>
          ) : (
            <Skeleton height="15px" width="150px" />
          )}
        </div>
      )}
      {/* <div className="flex-1" /> */}
      <button
        className="flex md:hidden gap-1 items-center underline text-primary-root hover:text-primary-700"
        onClick={handleMobileClick}
        data-testid="use-document-button"
      >
        <span>{t('components.block.OutputView.ConsumerPanel.usedocument')}</span>{' '}
        <KeyboardArrowDownIcon fontSize="inherit" />
        {isDownloading && <Spinner size="small" color="primary" />}
      </button>

      <div className="flex-10 gap-3 flex-wrap items-center justify-between hidden md:flex">
        {pdf && (
          <LinkWithIcon
            onClick={() => handleDownload('pdf')}
            label=".pdf"
            icon={<Icon type="pdf-16" size={20} />}
            spinner={isDownloading && downloadType === 'pdf'}
            data-testid="consumer-link-pdf"
          />
        )}
        {docx && (
          <LinkWithIcon
            onClick={() => handleDownload('docx')}
            label=".docx"
            icon={<Icon type="doc-16" size={20} />}
            spinner={isDownloading && downloadType === 'docx'}
            data-testid="consumer-link-docx"
          />
        )}
        {shareToken && (
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            title={
              hasCopied
                ? t('dashboard.topic.process.publishprocess.copied')
                : t('components.block.OutputView.ConsumerPanel.consentlink')
            }
          >
            <div>
              <LinkWithIcon
                onClick={() => handleCopyConsentLink()}
                label="Link"
                icon={<ShareIcon />}
                data-testid="consumer-link-consentlink"
              />
            </div>
          </Tooltip>
        )}
        {html && (
          <div className="flex-1">
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={
                hasCopied
                  ? t('dashboard.topic.process.publishprocess.copied')
                  : t('components.block.OutputView.ConsumerPanel.htmlcopied')
              }
            >
              <div>
                <LinkWithIcon
                  onClick={handleCopyHtml}
                  onMouseLeave={() => setTimeout(() => setHasCopied(false), 1000)}
                  label="HTML"
                  icon={<CodeIcon fontSize="small" />}
                  disabled={htmlLoading}
                  data-testid="consumer-link-html"
                />
              </div>
            </Tooltip>
          </div>
        )}
        {html && (
          <div className="flex-1">
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={t('components.block.OutputView.ConsumerPanel.cmshint')}
            >
              <div>
                <LinkWithIcon
                  onClick={() => setChunkModalOpen(true)}
                  label="HTML Chunks"
                  data-testid="consumer-link-chunks"
                  icon={<DataArrayIcon fontSize="small" />}
                />
              </div>
            </Tooltip>
          </div>
        )}
        {/* {html && (
          <div className="flex-1">
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={t('components.block.OutputView.ConsumerPanel.apihint')}
            >
              <div>
                <LinkWithIcon
                  onClick={() => setPluginModalOpen(true)}
                  label="Wordpress"
                  data-testid="consumer-link-wordpress"
                  icon={<ExtensionIcon fontSize="small" />}
                />
              </div>
            </Tooltip>
          </div>
        )} */}
        {html && (
          <div className="flex-1">
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              title={t('components.block.OutputView.ConsumerPanel.apihint')}
            >
              <HighlightBadge>
                <div>
                  <LinkWithIcon
                    onClick={() => setPluginModalOpen(true)}
                    label="API"
                    data-testid="consumer-link-wordpress"
                    icon={<ExtensionIcon fontSize="small" />}
                  />
                </div>
              </HighlightBadge>
            </Tooltip>
          </div>
        )}
        <LinkWithIcon
          onClick={handlePrint}
          label={t('common.print')}
          data-testid="consumer-link-print"
          icon={<Icon type="print-24" size={20} />}
        />
      </div>
      <LangSelect languages={['de', 'en']} inHeader={false} />
      {/* <div className="flex-1" /> */}
      <ChunkModal open={chunkModalOpen} onClose={() => setChunkModalOpen(false)} questionnaireId={questionnaireId} />
      <PluginModal
        open={pluginModalOpen}
        onClose={() => setPluginModalOpen(false)}
        questionnaireId={questionnaireId}
        apiDocId={apiDocId}
      />
      <Menu
        className=""
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {pdf && <MenuItem data-testid="consumer-link-pdf"
          onClick={() => handleDownload('pdf')}>.pdf</MenuItem>}
        {docx && <MenuItem data-testid="consumer-link-docx"
          onClick={() => handleDownload('docx')}>.docx</MenuItem>}
        {shareToken && <MenuItem data-testid="consumer-link-consentlink"
          onClick={() => handleCopyConsentLink()}>Link</MenuItem>}
        {html && <MenuItem data-testid="consumer-link-html"
          onClick={() => handleCopyHtml()}>HTML</MenuItem>}
        {html && <MenuItem data-testid="consumer-link-chunks"
          onClick={() => setChunkModalOpen(true)}>HTML Chunks</MenuItem>}
        {html && (
          <MenuItem data-testid="consumer-link-wordpress"
            onClick={() => setPluginModalOpen(true)}>
            <HighlightBadge inline={true}>
              <span>API</span>
            </HighlightBadge>
          </MenuItem>
        )}
        <MenuItem data-testid="consumer-link-print"
          onClick={handlePrint}>{t('common.print')}</MenuItem>
      </Menu>
    </div>
  );
}

interface LinkWithIconProps extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  label: string;
  icon: React.ReactNode;
  disabled?: boolean;
  spinner?: boolean;
}
function LinkWithIcon({ onClick, label, icon, disabled, spinner = false, ...props }: LinkWithIconProps) {
  return (
    <div className="flex-1">
      <button
        disabled={disabled}
        onClick={onClick}
        className={cx(
          'h-full w-full flex items-center justify-center text-primary-root gap-2',
          !disabled && 'hover:text-primary-800 underline'
        )}
        {...props}
      >
        {spinner ? <Spinner size="small" color="primary" /> : icon}

        <span className="break-keep">{label}</span>
      </button>
    </div>
  );
}

interface ChunkModalProps {
  open: boolean;
  onClose: ModalProps['onClose'];
  questionnaireId: string;
}

function ChunkModal({ open, onClose, questionnaireId }: ChunkModalProps) {
  const { t } = useTranslation();
  const { navigation } = useNavigation();
  const [chunkSize, setChunkSize] = useState(10000);
  const [getHtml, { data: htmlData, loading: htmlLoading }] = useGetQuestionnaireHtmlQuery();
  const chunks = htmlData?.getQuestionnaireHtml;
  const [hasCopied, setHasCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    getHtml({ variables: { id: questionnaireId, chunkSize } });
  };

  const handleCopy = (chunk: string) => {
    copyToClipboard(chunk)
      .then(() => {
        setHasCopied(true);
        if (navigation.isMobile) {
          enqueueSnackbar(t('dashboard.topic.process.publishprocess.copy_success'), { variant: 'success' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t('components.block.OutputView.ConsumerPanel.copyerror'), { variant: 'error' });
      });
  };

  return (
    <NotificationModal title="HTML Chunks" open={open} onClose={onClose}>
      <div className="flex flex-col gap-2 p-9">
        <h1 className="text">HTML Chunks</h1>
        <p>{t('components.block.OutputView.ConsumerPanel.cmshint')}</p>
        {/* an input field for the chunk size and a button to retrieve the chunks */}
        <div className="flex-col flex gap-2 md:flex-row">
          <label htmlFor="chunkSize">{t('components.block.OutputView.ConsumerPanel.maxchars')}</label>
          <input
            id="chunkSize"
            className="border border-gray-300 rounded-md p-2"
            type="number"
            data-testid="input-chunk-size"
            value={chunkSize}
            onChange={(e) => setChunkSize(parseInt(e.target.value))}
          />

          <Button onClick={handleClick} data-testid="button-get-chunks">
            {t('components.block.OutputView.ConsumerPanel.retrievchunks')}
          </Button>
        </div>
        {/* a list of the chunks */}
        {htmlLoading && <Spinner />}
        {chunks && (
          <table className="table-auto">
            <thead>
              <tr>
                <th>Chunk</th>
                <th>{t('components.block.OutputView.ConsumerPanel.characters')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {chunks?.map((chunk, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{chunk.length}</td>
                    <td>
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        title={hasCopied ? 'Kopiert!' : 'HTML in die Zwischenablage kopieren'}
                      >
                        <div>
                          <Link
                            to="#"
                            onClick={() => handleCopy(chunk)}
                            onMouseLeave={() => setTimeout(() => setHasCopied(false), 1000)}
                            data-testid={'button-copy-chunk-' + index}
                          >
                            {t('components.block.OutputView.ConsumerPanel.copy')}
                          </Link>
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </NotificationModal>
  );
}

interface PluginModalProps {
  open: boolean;
  onClose: ModalProps['onClose'];
  questionnaireId: string;
  apiDocId?: string;
}

// function PluginModal({ open, onClose, questionnaireId }: PluginModalProps) {
//   const { t } = useTranslation();
//   const [getToken, { data: tokenData, loading: tokenLoading }] = useQuestionnaireDocumentShareQuery();
//   const token = tokenData?.getQuestionnaireShareToken;
//   const [hasCopied, setHasCopied] = useState(false);
//   const { enqueueSnackbar } = useSnackbar();

//   useEffect(() => {
//     if (!questionnaireId) return;
//     getToken({
//       variables: {
//         localQuestionnaireId: questionnaireId,
//         type: 'Document',
//       },
//     });
//   }, [questionnaireId]);

//   const handleCopyClick = () => {
//     if (!token) return;
//     copyToClipboard(token)
//       .then(() => {
//         setHasCopied(true);
//       })
//       .catch((error) => {
//         enqueueSnackbar(t('components.block.OutputView.ConsumerPanel.copyerror'), { variant: 'error' });
//       });
//   };

//   return (
//     <NotificationModal title="HTML Chunks" open={open} onClose={onClose}>
//       <div className="flex flex-col gap-2 p-9">
//         <h1 className="text">Wordpress Plugin</h1>
//         <p>
//           <Trans t={t} i18nKey={'components.block.OutputView.ConsumerPanel.plugintoken'} values={{ token }}>
//             Binde das Dokument mit Hilfe unserer API direkt in Wordpress ein. Du kannst das Dokument dann über den
//             Shortcode <code>[dieter doc="{token}"]</code> in deinen Wordpress-Beitrag einbinden.
//           </Trans>
//         </p>
//         <p>
//           <a href="https://dieterfilestorage.blob.core.windows.net/public/dieter-privacy-wordpress.zip">
//             {t('components.block.OutputView.ConsumerPanel.install')}
//           </a>
//         </p>
//         <label htmlFor="token" className="text-sm font-semibold">
//           Document ID
//         </label>
//         <div className="flex">
//           <div className="flex-1">
//             <Input
//               id="token"
//               type="text"
//               value={token}
//               onFocus={(e) => e.target.select()}
//               textSize={'small'}
//               readOnly={true}
//               data-testid={'input-share-url'}
//             />
//           </div>
//           <div>
//             <Tooltip
//               enterTouchDelay={0}
//               leaveTouchDelay={5000}
//               title={hasCopied ? 'Kopiert!' : 'In Zwischenablage kopieren'}
//             >
//               <MuiButton
//                 onClick={handleCopyClick}
//                 onMouseLeave={() => setTimeout(() => setHasCopied(false), 1000)}
//                 data-testid={'button-copy-clipboard'}
//               >
//                 <ContentCopyIcon />
//               </MuiButton>
//             </Tooltip>
//           </div>
//         </div>
//       </div>
//     </NotificationModal>
//   );
// }

function PluginModal({ open, onClose, apiDocId }: PluginModalProps) {
  const { t, i18n } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const fullSnippet = apiDocId
    ? `<!-- Dieter Document API -->
<link rel="stylesheet" href="https://app.dieter-datenschutz.de/snippet/dieter-documents.css">
<script src="https://app.dieter-datenschutz.de/snippet/dieter-documents.js"
    data-document-id="${apiDocId}"
    data-target-selector="#dieter-document"
    data-gutter-width="1.5cm"
    data-locale="${i18n.language.slice(0, 2) === 'de' ? 'de' : 'en'}"
    data-no-styles="false">
</script>`
    : 'Loading...';

  const anchorSnippet = `<!-- Dieter Document Anchor -->
<div id="dieter-document"></div>`;

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    }
  }, [fullSnippet]); // Dependency array includes `fullSnippet` to re-apply effect when it changes

  return (
    <NotificationModal title="HTML Chunks" open={open} onClose={onClose}>
      <div className="flex flex-col gap-4 p-9">
        <h1 className="text">{t('components.block.OutputView.ConsumerPanel.apimodal.title')}</h1>
        <p>{t('components.block.OutputView.ConsumerPanel.apimodal.description')}</p>
        <Trans
          t={t}
          i18nKey={'components.block.OutputView.ConsumerPanel.apimodal.snippet1'}
          values={{ header: '<header/>' }}
        >
          <span>
            Übertrage diesen Code-Abschnitt in den{' '}
            <span className="font-mono text-sm text-primary-root">{'<header/>'}</span> deiner Webseite:
          </span>
        </Trans>
        <textarea
          ref={textareaRef}
          readOnly
          data-testid="api-snippet"
          className="w-full h-48 p-4 bg-gray-100 text-gray-800 font-mono text-xs border border-gray-300 rounded-md shadow-sm focus:ring-primary-root focus:outline-none overflow-y-hidden"
          value={fullSnippet}
        />
        <Trans
          t={t}
          i18nKey={'components.block.OutputView.ConsumerPanel.apimodal.snippet2'}
          values={{ body: '<body/>' }}
        >
          <span>
            Übertrage diesen Code-Abschnitt in den{' '}
            <span className="font-mono text-sm text-primary-root">{'<body/>'}</span> deiner Webseite an die Stelle an
            die das Dokument eingefügt werden soll:
          </span>
        </Trans>
        <textarea
          readOnly
          className="w-full h-fit p-4 bg-gray-100 text-gray-800 font-mono text-xs border border-gray-300 rounded-md shadow-sm focus:ring-primary-root focus:outline-none overflow-y-hidden"
          value={anchorSnippet}
        />
        <span className="font-semibold">{t('components.block.OutputView.ConsumerPanel.apimodal.paramtitle')}</span>
        <ul className="pl-0">
          <li>
            <code>data-document-id</code>: {t('components.block.OutputView.ConsumerPanel.apimodal.param1')}
          </li>
          <li>
            <code>data-target-selector</code> {t('components.block.OutputView.ConsumerPanel.apimodal.param2')}
          </li>
          <li>
            <code>data-gutter-width</code> {t('components.block.OutputView.ConsumerPanel.apimodal.param3')}
          </li>
          <li>
            <code>data-locale</code> {t('components.block.OutputView.ConsumerPanel.apimodal.param5')}
          </li>
          <li>
            <code>data-no-styles</code> {t('components.block.OutputView.ConsumerPanel.apimodal.param4')}
          </li>
        </ul>
        <div className="bg-secondary-100 border-l-4 border-secondary-500 text-secondary-700 p-4 mt-4">
          <p className="font-medium">{t('dashboard.consentmanagement.important')}:</p>
          {t('components.block.OutputView.ConsumerPanel.apimodal.disclaimer')}
        </div>
      </div>
    </NotificationModal>
  );
}

function HighlightBadge({ children, inline = false }: { children: JSX.Element; inline?: boolean }) {
  const { t } = useTranslation();
  if (inline) {
    return (
      <div className="flex gap-2 items-center">
        <div>{children}</div>
        <div className=" bg-red-200 text-xs text-red-600 rounded-full px-1 shadow-md">
          {t('components.ui.newbadge.new')}
        </div>
      </div>
    );
  } else {
    return (
      <div className="relative">
        <div className="">{children}</div>
        <div className="absolute -top-2 right-0 bg-red-200 text-xs text-red-600 rounded-full px-1 shadow-md">
          {t('components.ui.newbadge.new')}
        </div>
      </div>
    );
  }
}
