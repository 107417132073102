import { CtaButton } from '@app/components/ui/CtaButton/CtaButton';
import { useTasks } from '@app/context/TaskContext';
import { useUser } from '@app/context/UserContext';
import { useNavigation } from '@app/hooks/useNavigation';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { Timer } from '@components/block/Navigation/Timer';
import { CloseButton } from '@components/ui/CloseButton/CloseButton';
import { NewBadge } from '@components/ui/NewBadge/NewBadge';
import { UserFlowItem } from '@components/ui/UserFlowItem/UserFlowItem';
import { TaskType } from '@dieterApi/task/useTaskQuery';
import { QuestionnaireState } from '@legalosApi/types/QuestionnaireState';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Collapse, Portal, Tooltip, useMediaQuery } from '@mui/material';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskWithApp } from '../../Tasks/TaskList';
import { UserFlowItemType } from '../../constants';
import { LinkProcessBody } from './LinkProcessBody';
import { PublishProcessBody } from './PublishProcessBody';
import { SimpleProcessBody } from './SimpleProcessBody';
import { TutorialBody } from './TutorialBody';
import './processitem.sass';
interface Props {
  task: TaskWithApp;
  isFocusTask?: boolean;
  indentedLeft?: boolean;
  indentedRight?: boolean;
}

export function ProcessItem({ task, isFocusTask, indentedLeft, indentedRight }: Props) {
  const { t } = useTranslation();

  const { user } = useUser();
  const tasks = useTasks();
  const { navigation, setNavigation } = useNavigation();
  const { enqueueSnackbar } = useSnackbar();
  const isXL = useMediaQuery('(min-width: 1280px)');
  // const [showTaskTutorial, setShowTaskTutorial] = useState(false);
  // const [showTask, setShowTask] = useState(false);

  const taskDisabled = task.app?.status === 'DISABLED';
  const linkedQuestionnaireDone =
    task.linkedApplication &&
    user?.questionnaires?.find((q) => q.application.id === task.linkedApplication?.id)?.state ===
      QuestionnaireState.COMPLETE;

  // if user either is basic/premium subscriber or has topic activated with a ticket subscription
  const topicUnlocked =
    user?.isSubscriber &&
    (['Basic', 'Premium'].includes(user.subscription) || user.company.topicsActivated.includes(task.topic.id));

  useEffect(() => {
    if (task.linkedApplication) {
      // find questionnaires that are linked to this task, if it is COMPLETE, set task to complete
      // these tasks would normally directly refer to the linked application, but it is not needed, if user
      // has completed the linked questionnaire
      if (linkedQuestionnaireDone && !task.done) {
        task.setStatus(true);
      }
    }

    if (task.persistentId.includes('premium') && !task.done) {
      // close task if user has already booked premium
      user?.subscription === 'Premium' && task.setStatus(true);
    }

    if (task.persistentId.includes('basic') && !task.done) {
      // close task if user has already booked basic or premium
      user?.subscription === 'Basic' || (user?.subscription === 'Premium' && task.setStatus(true));
    }
  }, [task]);

  const handleTutorialOpen = () => {
    if (topicUnlocked || task.isUpsell || task.alwaysOpen) {
      setNavigation((nav) => {
        nav.taskOpen = { confirm: '', tutorial: task.id };
        nav.newTasks[task.persistentId] = false;
      });
    } else {
      setNavigation((nav) => {
        nav.roadBlock.modalOpen = true;
      });
    }
  };

  const handleTutorialClose = () => {
    setNavigation((nav) => {
      nav.taskTutorials = { ...nav.taskTutorials, [task.id]: true };
      nav.taskOpen = { confirm: task.id, tutorial: '' };
    });
  };

  const handleTaskClose = () => {
    setNavigation((nav) => {
      nav.taskOpen = { confirm: '', tutorial: '' };
    });
  };

  const handleTaskConfirm = () => {
    if (user?.isReadOnly) {
      enqueueSnackbar(t('dashboard.topic.process.processitem.readonly'), { variant: 'error' });
      return;
    }
    // set task to DONE
    handleTaskClose();
    setNavigation((nav) => {
      nav.animateBadge = true;
    });
    task.setStatus(true);
    window.scrollTo(0, 0);
  };

  const handleTaskReset = () => {
    task.setStatus(false);
    setNavigation((nav) => void (nav.newTasks[task.id] = true));
  };

  let subTaskBody: JSX.Element = <></>;
  switch (task.type) {
    case TaskType.PUBLISHPROCESS:
      subTaskBody = <PublishProcessBody task={task} onConfirm={handleTaskConfirm} type="Document" />;
      break;
    case TaskType.SHAREQUESTIONNAIRE:
      subTaskBody = <PublishProcessBody task={task} onConfirm={handleTaskConfirm} type="Questionnaire" />;
      break;
    case TaskType.SIMPLEPROCESS:
      subTaskBody = <SimpleProcessBody task={task} onConfirm={handleTaskConfirm} />;
      break;
    case TaskType.LINKQUESTIONNAIRE:
      subTaskBody = <LinkProcessBody task={task} onConfirm={handleTaskConfirm} />;
      break;
    case TaskType.QUESTIONNAIRE:
    case TaskType.REVISIT:
      break;
    default:
      const _exhaustiveCheck: never = task.type;
      break;
  }

  const showTaskTutorial = navigation.taskOpen.tutorial === task.id;
  const showTask = navigation.taskOpen.confirm === task.id;
  const thisTaskOpen = showTaskTutorial || showTask;

  const getPortalContainer = () => document.getElementById(`portal-container-${task.id}`);

  useEffect(() => {
    const portalContainer = getPortalContainer();
    if (!portalContainer) return;

    if ((showTaskTutorial || showTask) && !task.done) {
      portalContainer.classList.remove('hidden');
    } else {
      portalContainer.classList.add('hidden');
    }
  }, [showTaskTutorial, showTask, task.done]);

  return (
    <div className={cx(!thisTaskOpen && 'h-full')}>
      <UserFlowItem
        type={UserFlowItemType.task}
        title={t('common.section') + ': ' + task.topic.title}
        isFocusTask={isFocusTask}
        onIgnore={task?.setIgnore}
        isIgnored={task?.isIgnored}
        topRightElements={
          <div className={cx('flex w-full gap-3 place-content-end', { doneLabel: task.done })}>
            {task.done ? (
              <div className="flex flex-row gap-10 items-center content-end">
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  title={
                    task.resettable && !linkedQuestionnaireDone
                      ? t('dashboard.topic.process.processitem.resettask')
                      : t('dashboard.topic.process.processitem.not_resettable')
                  }
                >
                  <div>
                    <button
                      className={cx(
                        'restartButton',
                        (!task.resettable || linkedQuestionnaireDone) && 'cursor-not-allowed'
                      )}
                      onClick={handleTaskReset}
                      disabled={!task.resettable || linkedQuestionnaireDone}
                      data-testid={`button-task-restart-${task.persistentId}`}
                    >
                      <RestartAltIcon sx={{ width: '0.7em' }} />
                    </button>
                  </div>
                </Tooltip>

                <div>{t('common.done')}</div>
              </div>
            ) : (
              <>
                {task.isNew && <NewBadge />}
                <Timer duration={task.duration} />
              </>
            )}
          </div>
        }
        // weak={!!navigation.tutorialActive}
        done={task.done}
        bodyElements={
          <div className="w-full flex flex-col gap-2 ">
            <div className="grow flex flex-col gap-2">
              {/* <span className="text-gray-400 text-[0.8em] leading-3">
                {t('common.section') + ': ' + task.topic?.title}
              </span> */}
              <div className="text-lg">{task.label}</div>
            </div>
            <div className={cx(isFocusTask ? 'w-full md:w-1/2 self-end' : 'w-full')}>
              <CtaButton
                onClick={handleTutorialOpen}
                disabled={task.done}
                data-testid={'button-start-task-' + task.persistentId}
                btnColor={'gray'}
              >
                {taskDisabled
                  ? t('dashboard.topic.process.processitem.unlock')
                  : t('dashboard.topic.process.processitem.howitsdone')}
              </CtaButton>
            </div>
          </div>
        }
      />
      {
        <Portal container={getPortalContainer} disablePortal={!isXL}>
          <div className="mt-3">
            <Collapse in={(showTaskTutorial || showTask) && !task.done}>
              <UserFlowItem
                type={UserFlowItemType.task}
                // title={t('common.section') + ': ' + task.topic.title}
                bodyElements={
                  showTaskTutorial ? (
                    <TutorialBody task={task} onClose={handleTutorialClose} onConfirm={handleTaskConfirm} />
                  ) : (
                    subTaskBody
                  )
                }
                noBottom={true}
                title={<CloseButton onClick={handleTaskClose} offset={false} btnColor="gray" />}
                indentedLeft={indentedLeft || (!indentedLeft && !indentedRight)}
                indentedRight={indentedRight}
              />
            </Collapse>
          </div>
        </Portal>
      }
    </div>
  );
}
